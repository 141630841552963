import { testClass } from 'music';
import classes from './_image_toggle.module.scss';

/**
 * Image Toggle
 * Switch between two (or more) images on click or enter key
 * 
 * * Wraps the images in a button and tracks the index of the currently visible image.
 * * Click or Enter moves to the other (next) image in the sequence
 * * Can be any elements, not just images. Preferably non-interactive elements.
 * 
 * @class
 * 
 * @property {DOMElement} buttonElement - button that is wrapped around images
 * @property {DOMElement[]} imageElements - the provided images
 * @property maxIndex - the highest image index (number of images minus one)
 */
export default class UaImageToggle extends HTMLElement {
  /** 
   * Attributes to watch for changes 
   * index: numeric zero-based index (in source order) of the currently active image.
   */
  static get observedAttributes() {
    return ['index'];
  }

  /** Get the index of the currently active image  */
  getIndex() {
    return parseInt(this.getAttribute('index'));
  }

  /**
   * Show the indicated image and hide the rest.
   * 
   * @param newIndex - index of the new active image
   */
  #showActiveImage(newIndex) {
    if (this.imageElements) {
      this.imageElements.forEach((el, index) => {
        el.hidden = true;
      });

      this.imageElements[newIndex].hidden = false;
    }
  }
  
  /**
   * Call the method to update the images and fire an event
   * for ancestor elements to listen for if needed.
   * 
   * @private
   * @param oldIndex - index of the previously active image
   * @param newIndex - index of the new active image
   */
  #update(newIndex) {
    if (this.imageElements) {
      this.#showActiveImage(newIndex);
      const changeEvent = new CustomEvent('imagetogglechange', {
        detail: { index: newIndex }
      });
      this.dispatchEvent(changeEvent);
    }
  }


  /**
   * Switch to the other (next) image in the sequence.
   * Once past the last image, go back to the first.
   */
  advance() {
    const oldIndex = this.getIndex();
    const newIndex = (oldIndex + 1) % (this.maxIndex + 1);
    this.setAttribute('index', newIndex);
  }

  /**
   * Button to add interactivity.
   * At initialization time, images get copied inside the button.
   * 
   * @return {HTML} Markup for the button that will wrap the images.
   */
  get template() {
    return `
      <button 
        class="${classes['ua-image-toggle__button']}  ${testClass('img-toggle-btn')}"
        type="button">
      </button>
    `;
  }


  /** 
   * Initialization
   * 
   * * Set up template, copy images inside the button
   * * Hide all images by default
   * * 
   * * Set up cliock listener.
   */
  connectedCallback() {
    const imageMarkup = this.innerHTML;
    this.innerHTML = this.template;
    this.buttonElement = this.querySelector('button');
    this.buttonElement.innerHTML = imageMarkup;
    
    this.imageElements = Array.from(this.buttonElement.children);
    
    this.maxIndex = this.imageElements.length - 1;
    if (!this.hasAttribute('index')) {
      this.setAttribute('index', 0);
    }

    this.setAttribute('ariaLive', 'assertive');

    this.buttonElement.addEventListener('click', (event) => {
      event.stopPropagation();
      this.advance();
    });
    this.#update(this.getIndex());
  }

  /**
   * Respond to changes in index attribute.
   * 
   * * Update the view to correspond with the new index.
   * 
   * @param {string} name - Name of the changed attribute
   * @param {string} oldValue - Previous value
   * @param {string} newValue - New value
   */
  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'index') {
      newValue = parseInt(newValue);
      this.#update(newValue);
    }
  }
}
