import { BaseIcon } from 'music';
import { iconSvgData } from './_icon_svg_data.js';

/**
  @var {Object.<string, string>} iconSvgData - SVG markup, with icon names as keys.
*/

Object.keys(iconSvgData).forEach((iconName) => {
  const tagName = `music-icon-${iconName}`;
  customElements.define(tagName, class extends BaseIcon {
    /** @constructor */
    constructor() {
      super();
      this.label = this.label || iconName;
    }

    /** @return {String} - SVG data for a specific icon class */
    static get svg() {
      return iconSvgData[iconName];
    }
  });
});

/* No exports are necessary; the icons are registered with the browser. */
export default undefined;
