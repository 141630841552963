/*

  Plain Button - most styling removed.

*/
import classes from './_plain_button.module.scss';

export default class UaPlainButton extends HTMLElement {
  connectedCallback() {
    this.innerHTML =  `
      <button 
        type="${this.getAttribute('type') || 'button'}"
        class="${classes['ua-plain-button']}">
        ${this.innerHTML}
      </button>`;
  }
}
