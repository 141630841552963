import { createApp } from 'vue';
import UaBigSelect from '~/src/features/development/vite/design_system/vue_components/big_select/UaBigSelect.vue';

window.addEventListener(
  'DOMContentLoaded',
  () => {
    mountBigSelectAppsOnElms(UaBigSelect, '.js-ce-big-select-wrapper');
  }
);

const mountBigSelectAppsOnElms = (appClass, selector) => {
  const elms = document.querySelectorAll(selector);
  elms.forEach((elm) => {
    const props = { ...elm.dataset };
    if (props.listData) {
      props.listData = JSON.parse(props.listData);
    }
    if (props.disabled) {
      props.disabled = props.disabled === 'true';
    }
    if (props.selectedIndex) {
      props.selectedIndex = Number(props.selectedIndex);
    }

    const app = createApp(
      appClass,
      props
    );

    app.mount(elm);

  });
};