import { testClass } from 'music';
import styles from './_flash_banner.scss?inline';

export default class UaFlashBanner extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  /**
   * @return {Object} - mapping of flash variant names to icon names.
   */
  get icons() {
    return Object.freeze({
      'notice': 'info-circle',
      'success': 'check-circle',
      'warning': 'alert-triangle',
      'error': 'x-circle',
    });
  }

  /**
   * @return {String} - HTML template for component.
   */
  get template() {
    const variant = this.getAttribute('variant');
    const iconName = this.icons[variant];

    return `
      <div 
        class="ua-flash-banner  ua-flash-banner--${variant}  
               js-flash-banner ${testClass('flash-' + variant)}"
        role="alert">
        <div class="ua-flash-banner__content">
          <span slot="image" class="ua-flash-banner__icon  ${testClass('icon-container')}">
            <music-icon-m4-${iconName} size="lg" label="${variant}"></music-icon-m4-${iconName}>
          </span>
          <span slot="body" class="ua-flash-banner__message  ${testClass('flash-message')}">
            <slot></slot>
          </span>
          <button 
            class="ua-flash-banner__close-btn  js-close-btn ${testClass('close-btn')}">
            <music-icon-m4-x size="lg" label="Close" navigable></music-icon-m4-x>
          </button>
        </div>
      </div>
      <style>
        ${styles}
      </style>`;
  }

  /**
   * Set defaults for missing attributes.
   */
  setDefaults() {
    if (!this.hasAttribute('variant')) {
      this.setAttribute('variant', 'notice');
    }
  }

  /**
   * Initialize component when added to document.
   */
  connectedCallback() {
    this.shadowRoot.innerHTML = this.template;
    this.setDefaults();
    this.shadowRoot.querySelector('.js-close-btn').addEventListener('click', (event) => {
      this.setAttribute('hidden', true);
    })
  }
}
