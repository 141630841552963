import { createApp } from 'vue';

import UaDropdown from '~/src/features/development/vite/design_system/custom_elements/dropdown/UaDropdown.vue';

window.addEventListener(
  'DOMContentLoaded',
  () => {
    mountDropdownAppsOnElms(UaDropdown, '.js-ce-dropdown-wrapper');
  }
);

const mountDropdownAppsOnElms = (appClass, selector) => {
  const elms = document.querySelectorAll(selector);
  elms.forEach((elm) => {
    const props = { ...elm.dataset };
    if (props.listData) {
      props.listData = JSON.parse(props.listData);
    }
    if (props.disabled) {
      props.disabled = props.disabled === 'true';
    }
    if (props.selectedIndex && props.selectedIndex !== '') {
      props.selectedIndex = Number(props.selectedIndex);
    } else {
      props.selectedIndex = 0;
    }

    const app = createApp(
      appClass,
      props
    );

    app.mount(elm);
  });
};
