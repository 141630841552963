import './icons/index.js';
import GridTwo from './grids/grid_two.js';
import UaBubbleAlert from './bubble_alert/_ua_bubble_alert.js'
import UaDialog from './dialog/ua_dialog.js'
import UaDisclosure from './disclosure/ua_disclosure.js';
import UaFormItem from './form_item/form_item.js';
import UaFlashBanner from './flash_banner/flash_banner.js';
import UaIconWrapper from './icon_wrapper/icon_wrapper.js';
import UaImageToggle from './image_toggle/image_toggle.js';
import UaNeedHelpBox from './need_help_box/need_help_box.js';
import UaPlainButton from './plain_button/plain_button.js';
import UaRequired from './required/ua_required.js';
import UaSelectField from './select_field/select_field.js';
import UaTextField from './text_field/text_field.js';
import UaToggle from './toggle/ua_toggle.js';
import UaValidationMessage from './form_item/validation_message.js';

customElements.define('l-grid-two', GridTwo);
customElements.define('ua-bubble-alert', UaBubbleAlert);
customElements.define('ua-dialog', UaDialog);
customElements.define('ua-disclosure', UaDisclosure);
customElements.define('ua-form-item', UaFormItem);
customElements.define('ua-flash-banner', UaFlashBanner);
customElements.define('ua-icon-wrapper', UaIconWrapper);
customElements.define('ua-image-toggle', UaImageToggle);
customElements.define('ua-need-help-box', UaNeedHelpBox);
customElements.define('ua-plain-button', UaPlainButton);
customElements.define('ua-required', UaRequired);
customElements.define('ua-text-field', UaTextField);
customElements.define('ua-select-field', UaSelectField);
customElements.define('ua-toggle', UaToggle);
customElements.define('ua-validation-message', UaValidationMessage);
