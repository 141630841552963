import classes from './_form_item.module.scss';

/**
 * Wraps a form element or UaTextField to provide validation checking.
 * 
 * @class
 * 
 * @property {HTMLElement} input
 * @property {HTMLElement} validationBlock
 * @property {Boolean} required
 * @property {Boolean} mustContainNumber
 * @property {Boolean} userNameUnique
 * @property {Boolean} emailUnique
 * @property {Boolean} passwordsMustMatch
 * @property {undefined|Number} minlength
 */
export default class UaFormItem extends HTMLElement {
  input;
  validationBlock;

  /**
   * @param {string[]} list - Array of validation rule types.
   * @param {Object} validations - POJO of validations.
   * TODO: document validation POJO structure.
   */
  addValidations(list, validations) {
    this.validationHandlers = validations;
    const attrs = this.input.getAttributeNames();

    // Grab attributes that match keys in the validation rules
    this.inputRules = attrs.filter((attr) => {
      return list.includes(attr)
    });

    // Add a validation-message element for each existing rule
    this.inputRules.forEach((rule) => {
      this.addValidationMessageElements(rule, this.input);
    });
  }

  /**
   * Returns whether or not there are validation handlers.
   * @return {boolean}
   */
  hasValidations() {
    return !!this.validationHandlers;
  }

  /**
   * Returns overall validity of the form item.
   * If there are no validation handlers, return true.
   * If any validations fail, return false.
   */
  get isValid() {
    if (!this.hasValidations()) {
      // If there are no validations, the input is valid.
      return true;
      // TODO: this Object.valudes stuff could get cleaned up / extracted with a nicer interface.
    } else if (Object.values(this.validations).length === 0) {
      // If there is validation handling, but the validations have not
      // yet bee populated, run validations.
      this.validate();
    }

    // Check for overall validity by looping over rules, and determining if any failed.
    return !this.inputRules.map((rule) => this.validations[rule].isValid).includes(false);
  }

  get hasRequiredValidation() {
    return Object.values(this.inputRules).find(inputRule => inputRule === 'required') !== null;
  }

  /**
   * Run all validation handlers for the rules that apply to this form item.
   * Update the validity state for each associated ua-validation-message element.
   */
  validate() {
    if(this.inputRules) {
      this.inputRules.forEach((rule) => {
        this.validations[rule] = {
          isValid: this.validationHandlers[rule].isValid(this.input),
        }
      });
    }

    // TODO: Object.valudes stuff could get cleaned up / extracted with a nicer interface.
    Promise.all(
      Object.values(
        this.validations
      ).map((validation) => validation.isValid)
    ).then(() => {
      /* Once all validation callbacks are resolved,
       * Update the validity on the messages, which has a side effect of
       * updating the ua-validation-message templates.
       */

      // TODO: abstract out this selection to a method/property. 
      const messageElms = this.validationBlock.querySelectorAll('ua-validation-message');
      messageElms.forEach(this.setMessageElmValidity.bind(this));
      
    });
  }

  /**
   * Set validation message for neutral state.
   */
  setNeutralMessage() {
    const messageElms = this.validationBlock.querySelectorAll('ua-validation-message');
    messageElms.forEach((elm) => {
      elm.message = this.validationHandlers[elm.validationType].message(this.input);
      elm.state = 'neutral';
    });
  }

  /**
   * @property {UaValidationMessage]} elm
   * Sets the element's validity and its message.
   */
  async setMessageElmValidity(elm) {
    const isValid = await this.validations[elm.validationType].isValid;
    elm.message = this.validationHandlers[elm.validationType].message(this.input, isValid);
    elm.state = isValid ? 'success' : 'error';
  }

  /**
   * Create a UaValidationMessage element of the appropriate type and append it to
   * the validations block for this form item. If the value is not blank, run the validations.
   * 
   * @param {string} type - The validation type to assign to this element.
   * @param {HTMLElement} input - The input element for this form item.
   */
  addValidationMessageElements(type, input) {
    const className = `js-${input.id}-check`;
    const msg = document.createElement('ua-validation-message');
    msg.classList.add(className);
    msg.validationType =  type;
    msg.validationState = 'neutral'; // default value
    this.validationBlock.appendChild(msg);

    if (this.validationHandlers[type].allowNeutral) {
     this.setNeutralMessage();
    }

    if (input.value) {
      this.validate();
    }
  }

  /**
   * TODO: update this doc.
   */
  addRequiredAnnotation() {
    const requiredElm = document.createElement('ua-required');
    requiredElm.classList.add(classes['ua-form-item__required']);
    this.validationBlock.appendChild(requiredElm);
  }

  /**
   * TODO: update this doc.
   */
  setupEventListeners() {
    this.input.addEventListener('blur', this.validate.bind(this));
    this.input.addEventListener('input', this.validate.bind(this));
  }
  
  connectedCallback() {
    this.validations = {};
    if (this.querySelector('input, textarea, select')) {
      this.classList.add(classes['ua-form-item']);
      this.input = this.querySelector('input, textarea, select');
      this.validationBlock = document.createElement('div');
      this.validationBlock.classList.add(classes['validations']);
      this.validationBlock.setAttribute('aria-live', 'polite');
      this.appendChild(this.validationBlock);
      this.setupEventListeners();
      if (this.input.attributes.required && !this.input.hasAttribute('data-skip-required-marker')) {
        this.addRequiredAnnotation();
      }
    } else throw new Error('Must attach an input, textarea or select.');
    // TODO: handle case where more than one input is present?
  }
}
