/*

  Disclosure

*/
import styles from './_ua_disclosure.scss?inline';

export default class UaDisclosure extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    const summary = this.getAttribute('summary');
    this.shadowRoot.innerHTML = `
      <style>
        ${styles}
      </style>
      
      <details class="ua-disclosure">
        <summary class="ua-disclosure__summary">
          <span class="ua-disclosure__summary-text">${summary}</span>
          <music-icon-m4-arrow-drop-down size="xl" role="presentation" class="ua-disclosure__caret"></music-icon-m4-arrow-drop-down>
        </summary>
        <div class="ua-disclosure__content">
          <slot></slot>
        </div>
      </details>
    `;
  }
}
