/*

  Bubble alert

*/
import classes from './_ua_bubble_alert.module.scss'
import tippy from 'tippy.js';

export default class UaBubbleAlert extends HTMLElement {

  /** 
   * Render the component 
   */
  render() {
    this.variant = this.getAttribute('variant');
    if (this.hasAttribute('placement')) {
      this.placement = this.getAttribute('placement');
    } else {
      this.placement = 'top' // default
    }
    this.tooltipTargetElm = this.querySelector('[tooltip-target]');

    if (this.tooltipTargetElm != null) {
      this.removeChild(this.tooltipTargetElm);
      this.innerHTML = `
        ${this.tooltipTargetElm.outerHTML}
        ${this.template(this)}
      `;
    } else {
      this.innerHTML = this.template(this);
    }
  }

  /**
   * Component template
   * @return {String} - The HTML to be inserted into the document.
   */
  template() {
    return `
      <div class="${classes['ua-bubble']}  ${classes[`ua-bubble--${this.variant}`]} js-bubble" role="alert">
        ${this.innerHTML}
      </div>
    `;
  }

  /**
   * Create custom tooltip. 
   */
  createTooltip() {
    if (this.tooltipTargetElm != null) {
      tippy(
        this.querySelector('[tooltip-target]'),
        {
          allowHTML: true,
          content: this.querySelector('.js-bubble'),
          placement: this.placement,
          trigger: 'click mouseenter focus',
        }
      );
      this.makeClickableTooltip();
      this.tooltipTargetElm.classList.add('ua-bubble-tooltip-target');
    }
  }

  /**
   * Add tabindex if tootltip target is not a clikable element.
   */
  makeClickableTooltip() {
    const clickableTargets = ['A', 'BUTTON', 'INPUT', 'SELECT', 'TEXTAREA'];
    const tooltipTargetElm = this.querySelector('[tooltip-target]');

    if (!clickableTargets.includes(tooltipTargetElm.tagName)
      && !tooltipTargetElm.hasAttribute('tabindex')) {
      tooltipTargetElm.setAttribute('tabindex', 0);
    }
  }
  
  /**
   * Render the initial state.
   */
  connectedCallback() {
    this.render();
    this.createTooltip();
  }

};
