import styles from './_need_help_box.scss?inline';

export default class UaNeedHelpBox extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  /**
   * @return {Object} - mapping of need help variant names to icon names.
   */
  get icons() {
    return Object.freeze({
      'visit-store': 'shopping-cart',
      'guided-tour': 'route',
      'chat-agent': 'annotation-dots',
    });
  }

  /**
   * @return {String} - HTML template for component.
   */
  get template() {
    const variant = this.getAttribute('variant');
    const iconName = this.icons[variant];

    return `
      <div class="m4-need-help-item">
        <div class="m4-need-help__icon-wrapper">
          <music-icon-m4-${iconName} size="xl" class="m4-need-help__icon">
          </music-icon-m4-${iconName}>
        </div>
        <slot></slot>
      </div>
      <style>
        ${styles}
      </style>`;
  }

  /**
   * Initialize component when added to document.
   */
  connectedCallback() {
    this.shadowRoot.innerHTML = this.template;
  }
}
