/*

  Toggle

*/
import classes from './_ua_toggle.module.scss';

export default class UaToggle extends HTMLElement {
  /** Init: add classes and wrapper */
  connectedCallback() {
    const inputElement = this.querySelector('input');
    const labelElement = this.querySelector('label');

    this.classList.add(classes['m4-toggle-wrapper']);
    inputElement.classList.add(classes['m4-toggle']);
    labelElement.classList.add(classes['m4-label']);
  }
}

