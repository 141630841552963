import classes from './_validation_message.module.scss';

export default class UaValidationMessage extends HTMLElement {
  /**
   * @type {'minlength'|'required'|'must-contain-number'|'username-unique'|
   *        'email-unique'|'passwords-must-match'} Validation type.
   */
  validationType;

  /** @type {'neutral'|'error'|'success'} State of the validation message. */
  validationState;

  /** @type {number|string} Comparison value (for, e.g., minlength).  */
  validationValue;

  /**
   * Get the current validation state.
   * @return {'neutral'|'error'|'success'}
   */
  get state() {
    return this.validationState;
  }

  /**
   * Set the state of the validation message.
   * @param {'neutral'|'error'|'success'} newState
   */
  set state(newState) {
    if (['neutral', 'error', 'success'].includes(newState)) {
      this.validationState = newState;
      this.update();
    } else throw new Error('UaValidationMessage error: Invalid state.');
  }

  /**
   * Sets validity and transforms the validity into one of the allowed states.
   * @param {boolean} validity
   */
  set isValid(validity) {
    const newState = validity === true ? 'success' : 'error';
    this.state = newState;
  }

  /**
   * Template for inner markup.
   * @return {string} HTML string.
   */
  get template() {
    return `
      <${this.checkIconSelector}
        role="presentation"
        label=""
        size="24"
        class="${classes['validation-icon']}  ${classes['validation-icon--check']}">
      </${this.checkIconSelector}>
      <music-icon-m4-x
        role="presentation"
        label=""
        size="24"
        class="${classes['validation-icon']}  ${classes['validation-icon--x']}">
      </music-icon-m4-x>
      <span class="${classes['validation-message__text']}">
        ${this.message}
      </span>
    `;
  }

    /**
   * Selector for check icon markup.
   * @return {string} HTML string.
   */
  get checkIconSelector() {
    if (this.state === 'neutral') {
      return 'music-icon-m4-check'
    } else {
      return 'music-icon-m4-check-circle';
    }
  }

  /**
   * Render the message with the current state.
   */
  update() {
    this.innerHTML = this.template;

    switch(this.validationState) {
      case 'error':
        this.classList.add(classes['has-error'])
        this.classList.remove(classes['has-success'])
        break;
      case 'success':
        this.classList.add(classes['has-success']);
        this.classList.remove(classes['has-error']);
        break;
      case 'neutral':
        this.classList.remove(classes['has-success']);
        this.classList.remove(classes['has-error']);
        break;
    }
  }

  /**
   * Initialize and render the validation message.
   */
  connectedCallback() {
    this.update();
    this.classList.add(classes['validation-message']);
    this.classList.add(classes[`validation-message--${this.validationType}`]);
  }
}
