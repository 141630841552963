/*

  Dialog

*/
export default class UaDialog extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  /**
   * Styles for this element.
   * @return {String} - CSS for this element
   */
  styles() {
    return `
      dialog {
        --padding-h: 2.5rem;
        display: none;
        border: 0;
        border-radius: 1rem;
        box-shadow: 0 0.25rem 1.5rem 0 rgba(0, 0, 0, 0.15);
        background: var(--m4-util-white, #FFF);
        max-width: calc(75rem - var(--padding-h)); /** 1000px */
        max-height: 80%;
        padding: 2rem var(--padding-h);
        position: fixed;
        top: 4rem;
        bottom: 4rem;
      }

      dialog[open] {
        display: flex;
      }

      dialog > form {
        display: flex
      }

      .ua-panel {
        background-color: var(--m4-util-white, #FFF);
        display: flex;
        flex-direction: column;
      }

      .ua-panel > * {
        padding: 1rem 0;
      }

      .ua-panel__header {
        border-bottom: 1px solid var(--m4-true-gray-200, #BFBFBF);
        color: var(--m4-widget-red-700);
        line-height: 1.2;
        padding: 0 0 1rem 0;
      }
      
      .ua-panel__header h3 {
        margin: 0;
        font-size: var(--m4-font-heading-2, 2rem);
        font-weight: var(--m4-font-semibold);
      }

      .ua-panel__close-button {
        background: none;
        border: 0;
        color: var(--m4-util-text);
        cursor: pointer;
        font-size: 1.75rem;
        padding: 0;
      }

      .ua-panel__body {
        flex-grow: 1;
        margin: 1.5rem 0;
        overflow: auto;
        padding: 0;
        padding-right: 2rem;
        scroll-behavior: smooth;
        height: 100%;
        font-size: var(--m4-font-body-1, 1.25rem);
      }

      .ua-panel__footer {
        padding: 1.5rem 0 0;
      }

      .ua-panel__footer:has(slot[name="footer"]:empty) {
        padding: 0;
      }
      
      dialog::backdrop {
        background: rgba(255, 255, 255, 0.80);
        backdrop-filter: blur(0.25rem);
      }
    `;
  }

  /**
   * Component template
   * @param {UaDialog} instance - An instance of UaDialog
   * @return {String} - The HTML to be inserted into the document.
   */
  template() {
    return `
      <dialog>
        <form method="dialog">
          <div class="ua-panel">
            <header class="ua-panel__header">
              <l-spanner>
                <h3>${this.heading}</h3>
                <button class="ua-panel__close-button js-close">
                  <music-icon-m4-x navigable="true" size="xl"></music-icon-m4-x>
                </button>
              </l-spanner>
            </header>
            <div class="ua-panel__body  m4-scrollbar  m4-scrollbar-fade-effect  js-scrollbar-prevent-submit">
              <slot></slot>
            </div>
            <footer class="ua-panel__footer">
              <slot name="footer"></slot>
            </footer>
          </div>
        </form>
      </dialog>
    `;
  }

  /**
   * Attributes whose values will be watched for changes
   * @return {String[]}
   *
  */
  static get observedAttributes() {
    return ['heading'];
  }

  /**
   * Handle attribute value changes.
   *
   * @param {String} attrName - name of the attribute that has changed
   * @param {String} oldValue - previous value of the changed attribute
   * @param {String} newValue - new value of the changed attribute
   */
  attributeChangedCallback(attrName, oldValue, newValue) {
    if (newValue !== oldValue) {
      
      /** Value attributes */
      if (attrName === 'heading') {
        this[attrName] = newValue;
        this.render();
      }
    }
  }
  
  /**
   * Opens Modal.
   */
  showModal() {
    document.querySelector('body').style.overflow = 'hidden';
    this.shadowRoot.querySelector('dialog').showModal();
  }

  /**
   * Closes Modal.
   */
  closeModal() {
    document.querySelector('body').style.overflow = 'visible';
    this.shadowRoot.querySelector('dialog').close();
  }

  /**
   * Closes Modal. Alias of `closeModal`.
   */
  close() {
    this.closeModal();
  }

  /**
   * Attach event listeners
   */
  attachEvents() {
    document.querySelector('body').addEventListener('keyup', (evt) => {
      if (evt.which == 27 || evt.key == 'Escape') {
        this.closeModal();
      }
    });

    this.shadowRoot.querySelector('.js-close').addEventListener('click', () => {
        this.closeModal();
    });
  }

  /** Render the component */
  render() {
    this.shadowRoot.innerHTML = `
      <style>${this.styles()}</style>
      ${this.template()}
    `;
    this.attachEvents();
  }

  /**
   * Render the initial state.
   */
  connectedCallback() {
    this.render();
  }
}


