/*
  A Light DOM component wrapping a text input & label

  * Implements "sliding label" pattern to save space.
*/
import classes from './_text_field.module.scss';

export default class UaTextField extends HTMLElement {
  /* @return {Boolean} - whether input value is empty. */
  setEmptyOrNonEmpty() {
    this.empty = this.inputElement.value.length < 1;
    this.classList.toggle(classes['is-empty'], this.empty);
  }

  /* Attach classes and attributes to elements */
  decorate() {
    this.inputElement = this.querySelector('input');
    this.labelElement = this.querySelector('label');
    this.classList.add(classes[`theme-${this.getAttribute('theme') || 'm4'}`]);
    this.classList.add(classes['ua-text-field']);
    this.inputElement.classList.add(classes['ua-text-field__input']);
    this.labelElement.classList.add(classes['ua-text-field__label']);
  }

  /* Set initial state */
  init() {
    this.setEmptyOrNonEmpty();
  }

  /* Attach event listeners */
  attachEvents() {
    this.inputElement.addEventListener('focus', () => {
      this.setEmptyOrNonEmpty();
    });
    this.inputElement.addEventListener('blur', () => {
      this.setEmptyOrNonEmpty();
    });
  }

  connectedCallback() {
    this.decorate();
    this.attachEvents();
    this.init();
  }
}
