/*

  Icon wrapper

  A set of treatments for modifying icon appearance for various purposes.

  <icon-wrapper variant="success|error|info|warning|circle-outline">
    <music-icon-plus size="24"></music-icon-m4-plus>
  </icon-wrapper>

*/
import classes from './_icon_wrapper.module.scss';

export default class IconWrapper extends HTMLElement {
  connectedCallback() {
    this.classList.add(classes['icon-wrapper']);
  }
}
