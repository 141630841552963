<template>
  <svg
    viewBox="0 0 100 100"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Triangle</title>
    <g
      stroke="none"
      stroke-width="1"
      fill="var(--fill, #333)"
      fill-rule="evenodd">
      <polygon
        fill="inherit"
        points="49.788681 24 95 69 5 69" />
    </g>
  </svg>
</template>
