<template>
  <UaDropdownDisclosure
    class="ua-dropdown"
    :class="[
      testClass(name),
      { 'is-disabled': disabled },
    ]"
    :buttonId="buttonId"
    :data-helpable-type="helpableType"
    :descriptionElement="descriptionElement"
    :disabled="disabled"
    :expanded="ddStore.expanded"
    :headerTextOpen="selectedText"
    :headerTextClosed="selectedText"
    :label="label"
    :theme="theme"
    @keydown.esc="toggleDisclosure(false); setFocus('button')"
    @keydown.tab="toggleDisclosure(false)"
    @disclosure-up-arrow="toggleDisclosure(true); setFocus('last')"
    @disclosure-down-arrow="toggleDisclosure(true); setFocus('first')"
    @disclosure-focus="setFocus(-1)"
    @disclosure-click="toggleDisclosure(); setFocus('button')">
    <UaDropdownListbox
      :id="ddStore.listboxID"
      class="ua-dropdown__listbox  js-listbox"
      :class="[
        testClass(`${name}-listbox`),
        { 'scrollable': ddStore.scrollable },
      ]"
      :style="`
        top: ${ddStore.listboxTop};
        bottom: ${ddStore.listboxBottom};
        left: ${ddStore.listboxLeft}
      `"
      :items="listData"
      :theme="theme"
      @listbox-select="toggleDisclosure(false); setFocus('button'); emitChange()"
      @listbox-exit="toggleDisclosure(false)" />
    <select
      :id="name"
      ref="hiddenSelect"
      :name="name"
      :value="selectedOptionValue"
      style="display: none;">
      <option
        v-for="(option, index) in listData"
        :key="index"
        :value="option.value"
        :selected="ddStore.selectedIndex === index">
        {{ option.text }}
      </option>
    </select>
  </UaDropdownDisclosure>
</template>

<script setup>
  import { computed, onBeforeUnmount, onMounted, provide, ref } from 'vue';
  import { generateElementId, testClass } from 'music';
  import UaDropdownDisclosure from
  '~/src/features/development/vite/design_system/custom_elements/dropdown/subcomponents/ua_dropdown_disclosure/UaDropdownDisclosure.vue';
  import UaDropdownListbox from
  '~/src/features/development/vite/design_system/custom_elements/dropdown/subcomponents/ua_dropdown_listbox/UaDropdownListbox.vue';

  /** @typedef {import('./types.js').ListboxItem} ListboxItem */
  const props = defineProps({
    descriptionElement: {
      type: [String, null],
      default: null,
    },
    buttonId: {
      type: String,
      required: true,
    },
    disabled: { type: Boolean, default: false },
    name: {
      type: String,
      default: 'ua-dropdown',
    },
    helpableType: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    /** @type {ListboxItem[]} */
    listData: {
      type: Array,
      required: true,
    },
    selectedIndex: {
      type: Number,
      default: -1,
    },
    testSelector: {
      type: String,
      default: 'ua-dropdown',
    },
    theme: {
      type: String,
      default: 'm4',
    },
    value: {
      type: String,
      default: '',
    },
  });

  defineEmits(['activitydropdownchange']);

  const hiddenSelect = ref(null);

  const ddStore = ref({
    disclosureButtonId: props.buttonId,
    expanded: false,
    focusedIndex: -1,
    selectedIndex: -1,
    scrollable: false,
  });

  provide('dropdownStore', ddStore);

  const selectedText = computed(() => {
    if (
      ddStore.value &&
      ddStore.value?.selectedIndex >= 0
    ) {
      return props.listData[ddStore.value.selectedIndex].text;
    } else {
      return '';
    }
  });

  const indexFromValue = () => {
    const foundIndex = props.listData.findIndex((opt) => opt.value === props.value)
    return Math.max(foundIndex, 0);
  };

  const selectedOptionValue = computed(() => {
    if (
      ddStore.value &&
      ddStore.value?.selectedIndex >= 0
    ) {
      return props.listData[ddStore.value.selectedIndex].value;
    }
    return '';
  });

  const setFocus = (index) => {
    let newIndex;
    if (index === 'first') {
      newIndex = 0;
    } else if (index === 'last') {
      newIndex = props.listData.length - 1;
    } else if (index === 'button') {
      newIndex = -1;
    }
    ddStore.value.focusedIndex = newIndex;
  };

  const toggleDisclosure = (newState) => {
    ddStore.value.expanded = (typeof newState === 'boolean') ? newState : !ddStore.value.expanded;
    if (newState) emitOpen();
  };

  const clickDocBodyHandler = () => {
    toggleDisclosure(false);
  };

  const escapeKeyDocBodyHandler = (event) => {
    if (event.key === 'Escape') {
      toggleDisclosure(false);
    }
  };

  const aDropdownOpenedHandler = (evt) => {
    if (evt.detail != ddStore.value.listboxId) toggleDisclosure(false);
  };

  const emitOpen = () => {
    const opened = new CustomEvent('activitydropdownopened', {
      bubbles: true,
      cancelable: true,
      detail: ddStore.value.listboxId,
    });
    document.body.dispatchEvent(opened);
  };

  const emitChange = () => {
    // Native event for the activity shell to receive:
    const changed = new Event('activitydropdownchange', {
      bubbles: true,
      cancelable: true,
    });
    hiddenSelect.value.dispatchEvent(changed);
  };

  onMounted(async() => {
    ddStore.value.expanded = false;
    ddStore.value.selectedIndex = indexFromValue();
    ddStore.value.focusedIndex = -1;
    ddStore.value.listboxId = generateElementId('listbox');
    document.body.addEventListener('click', aDropdownOpenedHandler);
    document.body.addEventListener('click', clickDocBodyHandler);
    document.body.addEventListener('keydown', escapeKeyDocBodyHandler);
  });

  onBeforeUnmount(() => {
    document.body.removeEventListener('click', aDropdownOpenedHandler);
    document.body.removeEventListener('click', clickDocBodyHandler);
    document.body.removeEventListener('keydown', escapeKeyDocBodyHandler);
  });
</script>

<style lang="scss" scoped>
  @use 'music/app/styles/library/base' as * ;

  .ua-dropdown {
    display: inline-block;
    font-weight: normal;
    position: relative;
    width: 100%;

    &.is-disabled {
      border-color: var(--m4-role-disabled, $disabled-color);
    }

    &__listbox {
      position: fixed;
      width: fit-content;
      z-index: z(menu);
      border-radius: 1.5rem;
      background-color: var(--m4-util-white);

      &.scrollable {
        overflow-y: auto;
      }
    }
  }
</style>
