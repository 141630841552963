<template>
  <div
    class="c-listbox"
    :class="`theme-${theme}`">
    <ul
      :id="ddStore.listboxId"
      ref="listboxList"
      :class="[
        'c-listbox__list',
        testClass('dropdown-listbox'),
      ]"
      aria-orientation="vertical"
      lang="en"
      role="list">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="c-listbox__item"
        role="button"
        :aria-selected="ddStore.selectedIndex === index"
        :class="[
          item.classes,
          { 'is-focused': ddStore.focusedIndex === index },
        ]"
        :data-index="index"
        :value="item.value"
        :tabindex="0"
        @keydown="handleItemKeyDown($event, index)"
        @click="selectOption($event, index)"
        @focus="ddStore.focusedIndex = index">
        <span
          v-html="item.text" />
      </li>
    </ul>
  </div>
</template>

<script setup>
  import { inject, nextTick, ref, watch } from 'vue';
  import { testClass, validateEnum } from 'music';
  import CheckmarkIcon from '~/src/features/development/vite/design_system/custom_elements/dropdown/subcomponents/ua_dropdown_listbox/subcomponents/CheckmarkIcon.vue';

  /** @typedef {import('../types.js').ListboxItem} ListboxItem */

  const props = defineProps({
    /**
     * @type {ListboxItem[]} items
     */
    items: {
      type: Array,
      default: () => ([
        { text: 'Empty Select', value: 'option1' },
      ]),
    },
    theme: {
      type: String,
      default: 'supersite',
      validate: (val) => validateEnum(val, 'theme'),
    },
  });

  const emit = defineEmits([
    'listboxExit',
    'listboxSelect',
  ]);

  const ddStore = inject('dropdownStore');
  const listboxList = ref(null);

  watch(
    () => ddStore.value.focusedIndex,
    async (newIndex, oldIndex) => {
      if (newIndex >= 0) {
        if (listboxList.value) {
          // If the dropdown is just opening, wait til it's rendered:
          await nextTick();
          listboxList.value.children[newIndex].focus();
        }
      }
    }
  );

  const setFocus = async (index) => {
    ddStore.value.focusedIndex = index;
  };

  const selectOption = (event, index) => {
    ddStore.value.selectedIndex = index;
    event.preventDefault();
    emit('listboxSelect');
  };

  const handleItemKeyDown = (event, index) => {
    const key = event.key;
    let newIndex;

    if (key === 'ArrowDown') {
      newIndex = index + 1;
      if (newIndex >= props.items.length) newIndex = 0;
      event.preventDefault();
      setFocus(newIndex);
    }

    if (key === 'ArrowUp') {
      newIndex = index - 1;
      if (newIndex < 0) newIndex = props.items.length - 1;
      event.preventDefault();
      setFocus(newIndex);
    }

    if (key === 'Enter' || key === ' ') {
      selectOption(event, index);
    }

    if ((key === 'Tab' && !event.shiftKey) || key === 'Esc') {
      emit('listboxExit');
    }
  };
</script>

<style lang="scss" scoped>
@use 'music/app/styles/library/base' as *;
@use '../../themes/listbox';

.c-listbox {
  display: block;

  &__list {
    @include plain-list();

    margin: 0;
    padding: 0.5rem;
  }

  &__item {
    color: var(--listbox-option-text-color, #1D1D1D);
    background-color: var(--listbox-option-fill-color, $white);
    cursor: pointer;
    display: flex;
    font-weight: var(--m4-font-semibold);
    gap: 0.25rem;
    align-items: center;
    list-style-type: none !important;
    margin: 0;
    padding: var(--listbox-option-padding, 0.75rem);
    text-align: left;
    border: var(--listbox-option-edge, 0);

    > * {
      line-height: 1.2;
    }

    &:last-child {
      border-bottom: 0;
      border-bottom-left-radius: var(--listbox-option-hover-border-radius, 1.5rem);
      border-bottom-right-radius: var(--listbox-option-hover-border-radius, 1.5rem);
    }

    &:first-child{
      border-top-left-radius: var(--listbox-option-hover-border-radius, 1.5rem);
      border-top-right-radius: var(--listbox-option-hover-border-radius, 1.5rem);
    } 

    .c-listbox__icon {
      fill: transparent;
      height: var(--listbox-option-checkmark-size, 1rem);
      width: var(--listbox-option-checkmark-size, 1rem);
    }

    &[aria-selected="true"] {
      color: var(--listbox-option-selected-text-color, #1D1D1D);
      background-color: var(--listbox-option-selected-fill-color, #F2F2F2);
      border: var(--listbox-option-selected-edge, 0);
      border-radius: 1.5rem;

      .c-listbox__icon {
        fill: var(--listbox-option-checkmark-color, currentColor);
      }
    }

    &:hover {
      color: var(--listbox-option-hover-text-color, #1D1D1D);
      background-color: var(--listbox-option-hover-fill-color, #F2F2F2);
      border-radius: var(--listbox-option-hover-border-radius, 1.5rem);
    }

    &:focus,
    &.is-focused {
      color: var(--listbox-option-focus-text-color, $focus-ring-color);
      @include focus-standard();
      outline-offset: 0 !important;
      border-radius: var(--listbox-option-hover-border-radius, 1.5rem);
      background-color: var(--listbox-option-focus-fill-color, #F2F2F2);
    }
  }
}
</style>
