<template>
  <div
    class="c-dropdown-disclosure"
    :class="[
      testClass('dropdown-disclosure'),
      `theme-${theme}`,
    ]">
    <button
      :id="buttonId"
      ref="disclosureButton"
      class="c-dropdown-disclosure__button"
      :disabled="disabled"
      type="button"
      :class="[
        {'is-expanded': expanded},
        {'is-focused': ddStore.focusedIndex === -1},
        { 'is-empty': empty && label },
        testClass('dropdown-disclosure-button'),
      ]"
      :aria-controls="ddStore.listboxId"
      :aria-expanded="expanded"
      :aria-label="(ddStore.selectedIndex === 0) ? 'blank' : null"
      :aria-describedby="descriptionElement"
      @keydown.up.prevent="$emit('disclosureUpArrow')"
      @keydown.down.prevent="$emit('disclosureDownArrow')"
      @click.stop="$emit('disclosureClick'); setListBoxPosition()"
      @focus="$emit('disclosureFocus')">
      <label 
        v-if="label" 
        :for="buttonId" 
        class="c-dropdown-disclosure__button-label">
        {{ label }}
      </label>
      <div class="c-dropdown-disclosure__button-content">
        <div
          class="c-dropdown-disclosure__button-text"
          :class="[
            testClass(headerTextClass),
            headerTextClass]"
          v-html="headerLabel" />
        <span
          class="c-dropdown-disclosure__image"
          :class="testClass('dropdown-disclosure-image')"
          aria-hidden="true">
          <span class="embedded-icon  embedded-icon--sm">
            <ArrowIcon />
          </span>
        </span>
      </div>
    </button>
    <div
      ref="disclosureBody"
      class="c-dropdown-disclosure__body"
      :class="testClass('c-dropdown-disclosure-body')"
      :style="expanded ? 'display: block' : 'display: none'">
      <slot />
    </div>
  </div>
</template>

<script setup>
  import { computed, inject, nextTick, ref, watch, onMounted, onBeforeUnmount } from 'vue';
  import { testClass, validateEnum } from 'music';
  import ArrowIcon from '~/src/features/development/vite/design_system/custom_elements/dropdown/subcomponents/ua_dropdown_disclosure/subcomponents/ArrowIcon.vue';

  const props = defineProps({
    descriptionElement: {
      type: [String, null],
      default: null,
    },
    buttonId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    headerTextOpen: {
      type: String,
      required: true,
    },
    headerTextClosed: {
      type: String,
      required: true,
    },
    headerTextClass: {
      type: String,
      default: 'disclosure-header-text',
    },
    label: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'supersite',
      validate: (val) => validateEnum(val, 'theme'),
    },
  });

  defineEmits([
    'disclosureClick',
    'disclosureFocus',
    'disclosureUpArrow',
    'disclosureDownArrow',
  ]);

  const ddStore = inject('dropdownStore');
  const disclosureButton = ref(null);
  const disclosureBody = ref(null);
  const tableWrapperElm = ref(null);
  const listboxElm = ref(null);

  watch(
    () => ddStore.value.focusedIndex,
    async (newIndex) => {
      if (newIndex === -1) {
        await nextTick();
        disclosureButton.value.focus();
      }
    }
  );

  const headerLabel = computed(() => {
    return props.expanded ? props.headerTextOpen : props.headerTextClosed;
  });

  const setListBoxPosition = async () => {
    // Waits for rendering DropdownDisclosure then gets disclosureButton position.
    await nextTick();
    const disclosureBtnPos = disclosureButton.value.getBoundingClientRect();
    let newTop = `${disclosureBtnPos.bottom}px`;
    let newBottom = '';
    const newLeft = `${disclosureBtnPos.left}px`;
    let scrollable = false;

    if (listboxElm.value && listboxExceedsViewportHeight()) {
      newTop = '1rem';
      newBottom = '1rem';
      scrollable = true;
    } else if (listboxElm.value && listboxExceedsViewportBottom()) {
      newTop = '';
      newBottom = '1rem';
    } else if (listboxElm.value && listboxExceedsViewportTop()) {
      newTop = '1rem';
      newBottom = '';
    }

    ddStore.value.listboxTop = newTop;
    ddStore.value.listboxBottom = newBottom;
    ddStore.value.listboxLeft = newLeft;
    ddStore.value.scrollable = scrollable;
  };

  const listboxExceedsViewportHeight = () => {
    const listboxContentHeight = listboxElm.value.scrollHeight;
    const viewportHeight = window.innerHeight;
    return listboxContentHeight > viewportHeight;
  };

  const listboxExceedsViewportBottom = () => {
    const disclosureButtonBottom = disclosureButton.value.getBoundingClientRect().bottom;
    const listboxBounds = listboxElm.value.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const listboxDefaultBottom = disclosureButtonBottom + listboxBounds.height;
    return listboxDefaultBottom > viewportHeight;
  };

  const listboxExceedsViewportTop = () => {
    const disclosureButtonTop = disclosureButton.value.getBoundingClientRect().top;
    return disclosureButtonTop < 0;
  };

  const empty = computed(() => {
    return ddStore.value.selectedIndex === 0;
  });

  onMounted(async () => {
    tableWrapperElm.value = disclosureButton.value.closest('.js-table-wrapper');
    listboxElm.value = disclosureBody.value.querySelector('.js-listbox');
    let scrollableParent = document.querySelector('.js-scrollable-parent-elm');

    scrollableParent = scrollableParent ? scrollableParent : window;
    scrollableParent.addEventListener('scroll', setListBoxPosition);
    scrollableParent.addEventListener('resize', setListBoxPosition);
    if (tableWrapperElm.value) {
      tableWrapperElm.value.addEventListener('scroll', setListBoxPosition);
    }
  });

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', setListBoxPosition);
    window.removeEventListener('resize', setListBoxPosition);
    if (tableWrapperElm.value) {
      tableWrapperElm.value.removeEventListener('scroll', setListBoxPosition);
    }
  });
</script>

<style lang="scss" scoped>
  @use 'music/app/styles/library/base' as *;
  @use './_icon_styles' as *;
  @use '../../themes/disclosure';

  .c-dropdown-disclosure {
    border-width: 0;
    display: block;
    line-height: 1.5;
    position: relative;

    &__button {
      background-color: var(--disclosure-button-fill-color, $white);
      color: var(--disclosure-button-text-color, #1D1D1D);
      border: var(--disclosure-button-border, 0);
      border-radius: var(--disclosure-button-radius, 2rem);
      font-size: var(--disclosure-button-font-size, $font-size-16);
      cursor: pointer;
      display: inline-block;
      padding: var(--disclosure-button-padding, 1rem);
      text-align: left;
      width: 100%;
    }

    &__button-label {
      position: absolute;
      color: var(--m4-text-color-quiet, #555);
      font-size: var(--disclosure-button-minified-font-size, $font-size-12);
      transition: top 0.3s, left 0.3s, color 0.3s, font-size 0.3s;
      transform-origin: 0 center;
      left: 1rem;
      top: 0;

      .is-empty > & {
        color: var(--disclosure-button-text-color, #1D1D1D);
        font-size: var(--disclosure-button-font-size, $font-size-16);
        pointer-events: none;
        top: var(--disclosure-button-padding, 1rem);
      } 
    }

    &__button-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__image {
      --fill: var(--disclosure-triangle-fill-color, #AA3127);

      display: inline-block;
      line-height: 0;
      margin-bottom: 0;
      position: relative;
      rotate: 180deg;
      transform-origin: 50%;
      transition: transform 0.2s;
      vertical-align: middle;

      .is-expanded & { rotate: 180deg }

      @media screen and (prefers-reduced-motion) {
        transition: none;
      }

    }

    &__button-text {
      flex-grow: 1;
      font-style: var(--disclosure-button-text-style, normal);
      font-weight: var(--disclosure-button-text-weight, normal);
      line-height: 1;
      margin: 0 mod(0.5) 0 0;
      text-transform: var(--disclosure-button-text-case, none);
      white-space: nowrap;

      .dropdown-disclosure--start & {
        margin: 0 0 0 mod(0.25);
      }
    }

    &__body {
      padding: 0;
    }

    &__button:disabled {
      background-color: var(--disclosure-button-disabled-fill-color, #BFBFBF);
      color: var(--disclosure-button-disabled-text-color, #595959);
      filter: grayscale(1);

      .c-dropdown-disclosure__image {
        opacity: 0.6;
      }
    }
  }
</style>
