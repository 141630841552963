
import stylesheet from './_ua_required.scss?inline';

export default class UaRequired extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    this.shadowRoot.innerHTML = `
      <style>${stylesheet}</style>
      <music-icon-m4-asterisk 
        class="icon" 
        label="asterisk"
        size="md"></music-icon-m4-asterisk>
      <span class="text">Required</span>
    `;
  }
}
